exports.components = {
  "component---src-blog-template-blog-template-js": () => import("./../../../src/blog-template/blog-template.js" /* webpackChunkName: "component---src-blog-template-blog-template-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-accessibility-notice-js": () => import("./../../../src/pages/accessibility-notice.js" /* webpackChunkName: "component---src-pages-accessibility-notice-js" */),
  "component---src-pages-appointment-js": () => import("./../../../src/pages/appointment.js" /* webpackChunkName: "component---src-pages-appointment-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-services-blood-pressure-js": () => import("./../../../src/pages/services/blood-pressure.js" /* webpackChunkName: "component---src-pages-services-blood-pressure-js" */),
  "component---src-pages-services-diabetes-js": () => import("./../../../src/pages/services/diabetes.js" /* webpackChunkName: "component---src-pages-services-diabetes-js" */),
  "component---src-pages-services-men-health-js": () => import("./../../../src/pages/services/men-health.js" /* webpackChunkName: "component---src-pages-services-men-health-js" */),
  "component---src-pages-services-primary-care-js": () => import("./../../../src/pages/services/primary-care.js" /* webpackChunkName: "component---src-pages-services-primary-care-js" */),
  "component---src-pages-services-std-testing-js": () => import("./../../../src/pages/services/std-testing.js" /* webpackChunkName: "component---src-pages-services-std-testing-js" */),
  "component---src-pages-services-weight-loss-js": () => import("./../../../src/pages/services/weight-loss.js" /* webpackChunkName: "component---src-pages-services-weight-loss-js" */),
  "component---src-pages-services-women-health-js": () => import("./../../../src/pages/services/women-health.js" /* webpackChunkName: "component---src-pages-services-women-health-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../../../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */)
}

