import React from 'react';
import { buttonPrimary, container, fontStyle, navListItem } from "../../styles/styles";
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import './navigation.css';

const Navbar = () => {

    React.useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 10) {
                document.querySelector('#navigationBar').classList.add('onScrollNav');
            }
            else {
                document.querySelector('#navigationBar').classList.remove('onScrollNav');
            }
        });
    }, []);

    const [isMobileMenuOpen, setMobileMenuOpen] = React.useState(false);
    const toggleMobileMenu = () => {
        setMobileMenuOpen(!isMobileMenuOpen);
    };

    return (
        <div>
            <nav className="border-gray-200 bg-primary-light p-2">
                <div className={`${container}`}>
                    <div className="flex justify-end flex-wrap px-4">
                        <div className="flex items-center my-1 mr-0 sm:mr-10">
                            <StaticImage className='w-fit h-fit' src="../../assets/icons/locationIcon.svg" alt='' />
                            <p className={`${fontStyle} text-white !text-[10px] sm:!text-[12px] ml-3`}>2107 Eldorado Pkwy, Suite 106, McKinney, TX 75070</p>
                        </div>
                        <div className="flex items-center my-1">
                            <StaticImage className='w-fit h-fit' src="../../assets/icons/callIcon.svg" alt='' />
                            <a href='tel:469-919-0003' className='pointer flex items-center' aria-label='phone'>
                                <p className={`${fontStyle} text-white !text-[10px] sm:!text-[12px] ml-3`}>469-919-0003</p>
                            </a>
                        </div>
                    </div>
                </div>
            </nav>

            <nav id='navigationBar' className={`container xl:max-w-[2000px] xl:w-[1250px] lg:max-w-[2000px] lg:w-[1000px] xmd:max-w-[800px] md:max-w-[690px] sm:max-w-[510px] xs:max-w-[80%] mx-auto xs:max-w-none sm:max-w-none xmd:max-w-none md:max-w-none lg:max-w-screen-lg border-gray-200 bg-secondary-dark p-1 rounded-none xl:rounded-full mt-0 lg:mt-6 fixed left-0 right-0 transition-all ease-in-out duration-300 z-[5]`}>
                <div className={`${container} flex flex-wrap items-center justify-between mx-auto py-4 px-8`}>
                    <Link to="/" className="flex items-center space-x-3 rtl:space-x-reverse">
                        <StaticImage className='object-cover w-[130px]' src="../../assets/logo/synergyLogo.png" alt='' />
                    </Link>
                    <div className="flex xl:order-2 space-x-3 xl:space-x-0 rtl:space-x-reverse">
                        <a href='https://health.healow.com/synergyprimaryclinic' rel="noreferrer" target="_blank" type="button" className={buttonPrimary}>Patient Portal</a >

                        <button data-collapse-toggle="navbar-cta" onClick={() => toggleMobileMenu()} type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm rounded-lg xl:hidden hover:bg-gray-100 focus:outline-none text-gray-400" aria-controls="navbar-cta" aria-expanded={isMobileMenuOpen ? 'true' : 'false'}>
                            <span className="sr-only">Open main menu</span>
                            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
                            </svg>
                        </button>
                    </div>

                    <div className={`items-center justify-between w-full xl:flex xl:w-auto xl:order-1 transition-all ease-in-out ${isMobileMenuOpen ? "" : "hidden"}`} id="navbar-cta">
                        <ul className="flex flex-col font-medium p-4 xl:p-0 mt-4 rounded-lg xl:space-x-8 rtl:space-x-reverse xl:flex-row xl:mt-0 transition-all ease-in-out float-none sm:float-left">
                            <li className='self-start xl:self-center'>
                                <Link to="/" activeClassName="font-bold" onClick={() => {
                                    setMobileMenuOpen(false);
                                }} className="block py-2 px-3 xl:p-0 text-black text-[13px]" >Home</Link>
                            </li>
                            <li className='self-start xl:self-center'>
                                <Link to="/about-us" onClick={() => {
                                    setMobileMenuOpen(false);
                                }} activeClassName="font-bold" className="block py-2 px-3 xl:p-0 text-black text-[13px]">About</Link>
                            </li>
                            <li className='self-start xl:self-center'>
                                <div className="mx-auto flex w-full items-center justify-center">
                                    <div className="group relative cursor-pointer">
                                        <div className="flex items-center justify-between space-x-5">
                                            <button className="menu-hover flex items-center justify-between w-full p-3 rounded text-black text-[13px]">Services
                                                <svg className="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                                                </svg>
                                            </button>
                                        </div>
                                        <div className="dropdown invisible absolute z-50 flex rounded-xl w-max flex bg-gray-100 text-gray-800 shadow-md opacity-0 transition-opacity ease-in-out duration-300">
                                            <div>
                                                <Link onClick={() => {
                                                    setMobileMenuOpen(false);
                                                }} to='/services/diabetes' className={`${navListItem}`}>Diabetes</Link>
                                                <Link onClick={() => {
                                                    setMobileMenuOpen(false);
                                                }} to='/services/blood-pressure' className={`${navListItem}`}>High Blood Pressure</Link>
                                                <Link onClick={() => {
                                                    setMobileMenuOpen(false);
                                                }} to='/services/men-health' className={`${navListItem}`}>Men's Health</Link>
                                                <Link onClick={() => {
                                                    setMobileMenuOpen(false);
                                                }} to='/services/primary-care' className={`${navListItem}`}>Primary Care</Link>
                                            </div>
                                            <div>
                                                <Link onClick={() => {
                                                    setMobileMenuOpen(false);
                                                }} to='/services/std-testing' className={`${navListItem}`}>STD Testing</Link>
                                                <Link onClick={() => {
                                                    setMobileMenuOpen(false);
                                                }} to='/services/weight-loss' className={`${navListItem}`}>Weight Loss </Link>
                                                <Link onClick={() => {
                                                    setMobileMenuOpen(false);
                                                }} to='/services/women-health' className={`${navListItem}`}>Women's Health</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className='self-start xl:self-center'>
                                <Link onClick={() => {
                                    setMobileMenuOpen(false);
                                }} to="/blog" activeClassName="font-bold" className="block py-2 px-3 xl:p-0 text-black text-[13px]">Articles</Link>
                            </li>
                            <li className='self-start xl:self-center'>
                                <Link onClick={() => {
                                    setMobileMenuOpen(false);
                                }} to="/testimonials" activeClassName="font-bold" className="block py-2 px-3 xl:p-0 text-black text-[13px]">Testimonials</Link>
                            </li>
                            <li className='self-start xl:self-center'>
                                <div className="mx-auto flex w-full items-center justify-center">
                                    <div className="group relative cursor-pointer">
                                        <div className="flex items-center justify-between space-x-5">
                                            <button className="menu-hover flex items-center justify-between w-full p-3 rounded text-black text-[13px]">Intake Forms
                                                <svg className="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                                                </svg>
                                            </button>
                                        </div>
                                        <div className="dropdown invisible absolute z-50 flex rounded-xl w-max flex bg-gray-100 text-gray-800 shadow-md opacity-0 transition-opacity ease-in-out duration-300">
                                            <div>
                                                <a onClick={() => {
                                                    setMobileMenuOpen(false);
                                                }} href='/IntakeForm1.docx' download className={`${navListItem}`}>Intake Form (Word)</a>
                                                <a onClick={() => {
                                                    setMobileMenuOpen(false);
                                                }} href={'/IntakeForm2.pdf'} download className={`${navListItem}`}>Intake Form (PDF)</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className='self-start xl:self-center'>
                                <Link onClick={() => {
                                    setMobileMenuOpen(false);
                                }} to="/appointment" activeClassName="font-bold" className="block py-2 px-3 xl:p-0 text-black text-[13px]">Appointment</Link>
                            </li>
                            <li className='self-start xl:self-center mt-3'>
                                <a href='https://health.healow.com/synergyprimaryclinic' rel="noreferrer" target="_blank" className="transition ease-in-out delay-150 text-white focus:outline-none font-medium rounded-full text-sm px-5 py-3 text-center bg-primary-light hover:bg-primary-light_hover block sm:hidden">Patient Portal</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
};

export default Navbar;