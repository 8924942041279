import React from 'react';
import { Helmet } from 'react-helmet';
import Navbar from './header/Navigation';
import Footer from './footer/Footer';

const MainLayout = ({ children }) => {
    return (
        <>
            <Helmet>
                <link href="https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700;800&family=Poppins:wght@300&display=swap" rel="stylesheet" />
                <link rel="icon" type="image/x-icon" />
                <link rel="apple-touch-icon" />
            </Helmet>
            <Navbar />
            {children}
            <Footer />
        </>);
};


export default MainLayout;